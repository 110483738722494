<template>
  <div>
    <h2>{{ $t('grazingProposals.title') }}</h2>
    <p>{{ $t('grazingProposals.description') }}</p>
    <div class="recommendation" v-if="springRecommendation()">
      {{ $t('grazingProposals.springRecommendation') }}
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>{{ $t('grazingProposals.tableHeaders.name') }}</th>
            <th>{{ $t('grazingProposals.tableHeaders.actions') }}</th>
            <th>{{ $t('grazingProposals.tableHeaders.size') }}</th>
            <th>{{ $t('grazingProposals.tableHeaders.accessClosed') }}</th>
            <th>{{ $t('grazingProposals.tableHeaders.details') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(zone, index) in grazingProposals.availableZones" :key="index">
            <td>{{ zone.zoneName }}</td>
            <td>
              <ZoneAccessIcon :zone="zone" @reload="listGrazingProposals" />
            </td>
            <td>{{ zone.zoneSize }} &#x33A1;</td>
            <td>{{ formatDate(zone.accessClosed) }}</td>
            <td><v-icon x-large @click.stop="showReport(zone.id)">mdi-camera-image</v-icon></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn v-if="!allProposals" @click="showAllProposals">{{ $t('grazingProposals.btnShowAll') }}</v-btn>
  </div>
</template>

<script>
import formatting from '@/mixins/formatting'
import ZoneAccessIcon from './ZoneAccessIcon.vue'

export default {
  name: 'GrazingProposals',

  mixins: [formatting],

  data: () => ({
    grazingProposals: {},
    allProposals: false
  }),

  beforeMount () {
    this.listGrazingProposals()
  },

  methods: {
    springRecommendation () {
      const today = new Date()
      const month = today.getMonth()

      return month >= 3 && month <= 4
    },

    listGrazingProposals () {
      fetch('/api/grazing/proposals', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.grazingProposals = data
        })
    },
    showReport (zoneId) {
      this.$router.push({ name: 'ZoneReport', params: { zoneId } })
    },
    showAllProposals () {
      fetch('/api/grazing/proposals/all', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.grazingProposals = data
          this.allProposals = true
        })
    }
  },
  components: { ZoneAccessIcon }
}
</script>

<style scoped>
.recommendation {
  background-color: #e2fa9f;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
