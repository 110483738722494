<template>
  <v-card>
    <v-card-title>{{ $t('grazingReport.title') }}</v-card-title>
    <v-data-table data-cy="zones" :items="zones" :headers="headers" :items-per-page=-1 sort-by="zoneName">
      <template v-slot:[`item.accessState`]="{ item }">
        <span v-if="item.accessState != null">{{ $t('access.' + item.accessState) }}</span>
      </template>
      <template v-slot:[`item.accessOpened`]="{ item }">
        {{ formatDateTime(item.accessOpened) }}
      </template>
      <template v-slot:[`item.accessClosed`]="{ item }">
        {{ formatDateTime(item.accessClosed) }}
      </template>
      <template v-slot:[`item.averageDuration`]="{ item }">
        {{ calculateAverageDuration(item.grazingSessions) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import i18n from '@/i18n'
import formatting from '@/mixins/formatting'
import { calculateAverageDuration as helperCalculateAverageDuration } from '@/helpers.js'

export default {
  name: 'GrazingReport',
  mixins: [formatting],

  data () {
    return {
      zones: [],
      headers: [
        { text: i18n.t('grazingReport.zone'), value: 'zoneName' },
        { text: i18n.t('grazingReport.averageSessionDuration'), value: 'averageDuration' },
        { text: i18n.t('grazingReport.quality'), value: 'zoneQuality' },
        { text: i18n.t('grazingReport.state'), value: 'accessState' },
        { text: i18n.t('grazingReport.opened'), value: 'accessOpened' },
        { text: i18n.t('grazingReport.closed'), value: 'accessClosed' }
      ],
      showEditDialog: false,
      editItem: null
    }
  },

  methods: {
    calculateAverageDuration (sessions) {
      return helperCalculateAverageDuration(sessions)
    },

    loadZones () {
      return fetch('/api/zones/managed-access', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zones = data.zones
        })
    }
  },

  beforeMount () {
    this.loadZones()
  }
}
</script>
