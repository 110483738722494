<template>
  <div>
    <v-card>
      <v-card-title>{{ $t('access.title') }}</v-card-title>
      <v-data-table data-cy="zones" :items="zones" :headers="headers" :items-per-page=-1 sort-by="zoneName">
        <template v-slot:item="{ item }">
          <tr :class="{ blockedZone: item.access === 'blocked' }">
            <td>{{ item.zoneName }}</td>
            <td>{{ formatDateTime(item.accessNext) }}</td>
            <td nowrap>
              <ZoneAccessIcon :zone="item" v-on:reload="loadZones()" />
              <v-icon x-large @click.stop="showReport(item.id)">mdi-camera-image</v-icon>
              <v-icon x-large @click="rowClicked(item)">
                mdi-pencil
              </v-icon>
            </td>
            <td>
              <span v-if="item.accessState != null">{{ $t('access.' + item.accessState) }}</span>
            </td>
            <td>{{ item.zoneUsage }}</td>
            <td v-if="item.accessOpened != null">{{ formatDateTime(item.accessOpened) }}</td>
            <td v-if="item.accessOpened == null"></td>
            <td v-if="item.accessClosed != null">{{ formatDateTime(item.accessClosed) }}</td>
            <td v-if="item.accessClosed == null"></td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog v-model="showEditDialog">
        <v-card>
          <v-card-title>Edit Access to Zone</v-card-title>
          <v-card-text>
            <v-container>
              <v-col v-if="editItem != null">Opened: {{ editItem.accessOpened }} <v-icon @click.stop="removeOpened()">
                  mdi-delete</v-icon>
              </v-col>
              <v-col v-if="editItem != null">Closed: {{ editItem.accessClosed }} <v-icon @click.stop="removeClosed()">
                  mdi-delete</v-icon>
              </v-col>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.stop="showEditDialog = false">Close</v-btn>
              <v-btn @click.stop="saveEditedState()" color="primary">Save</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import i18n from '@/i18n'
import ZoneAccessIcon from './ZoneAccessIcon.vue'
import formatting from '@/mixins/formatting.js'

export default {
  name: 'GrazingAccess',
  components: { ZoneAccessIcon },
  mixins: [formatting],

  data: () => ({
    search: '',
    headers: [
      { text: i18n.t('terms.tableHeaders.name'), align: 'start', value: 'zoneName' },
      { text: i18n.t('terms.tableHeaders.next'), align: 'start', value: 'accessNext' },
      { text: i18n.t('terms.tableHeaders.actions'), value: 'actions', sortable: false },
      { text: i18n.t('terms.tableHeaders.state'), align: 'start', value: 'accessState' },
      { text: i18n.t('terms.tableHeaders.usageType'), align: 'start', value: 'zoneUsage' },
      { text: i18n.t('terms.tableHeaders.opened'), align: 'start', value: 'accessOpened' },
      { text: i18n.t('terms.tableHeaders.closed'), align: 'start', value: 'accessClosed' }
    ],
    zones: [],
    showEditDialog: false,
    editItem: {}
  }),

  beforeMount: function () {
    this.loadZones()
  },

  methods: {
    loadZones () {
      return fetch('/api/zones/managed-access', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.zones = data.zones
        })
    },

    rowClicked (item) {
      this.editItem = item
      this.showEditDialog = true
    },

    removeOpened () {
      this.editItem.accessOpened = null
    },

    removeClosed () {
      this.editItem.accessClosed = null
    },

    saveEditedState () {
      fetch(`/api/zones/managed-access/${this.editItem.id}`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`,
          'Content-type': 'application/json'
        },
        body: JSON.stringify(this.editItem)
      })
        .then(() => {
          this.showEditDialog = false
          this.editItem = null
        })
        .then(() => { this.loadZones() })
    },

    showReport (zoneId) {
      this.$router.push({ name: 'ZoneReport', params: { zoneId } })
    }

  }
}
</script>

<style>
.blockedZone {
  color: silver;
}
</style>
