<template>
  <GrazingProposals />
</template>

<script>
import GrazingProposals from './GrazingProposals'

export default {
  name: 'GrazingOverview',
  components: {
    GrazingProposals
  }
}
</script>
